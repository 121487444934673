.btnMenu{
  color: #fff;
  background-color: #ff172d;
  border-color: #ff172d;
  border: none !important;
  outline: none !important;
  border-radius: 2px;
  text-align: center;
}

.aboutmodify h6{
 color: black;
 font-size: 30px !important;
}
.aboutmodify p{
  color: black;
  font-size: 19px;
}
.lookingfor p{
  color: black;
  font-size: 19px;
}
/* .aboutpage h6{
  color: rgb(78, 77, 77);
} */
.logodesign img{
  width: 90px;
}
/* Blog Category */
.blog-cat {
  position: relative;
  padding: 0;
}

.blog-cat li {
  position: relative;
  list-style-type: none;
  margin-bottom: 6px;
}

.blog-cat li a {
  position: relative;
  color: var(--heading-color);
  font-size: 18px;
  font-weight: 600;
  display: block;
  line-height: 25px;
  display: flex;
  align-items: baseline;
  width: 100%;
  transition: .3s ease;
}
.blog-cat li a label {
  font-size: 14px;
  margin-left: auto;
  width: 27px;
  height: 27px;
  font-size: 12px;
  text-align: center;
  line-height: 27px;
  border-radius: 6px;
  background-color: var(--bg-light);
}
ul.blog-cat li a span.fa {
  color: var(--primary-color);
  font-size: inherit;
  background: none;
  /* font-size: 16px; */
  margin-right: 8px;
}
ul.twitter-feed a {
  display: grid;
  grid-template-columns: 1fr 9fr;
  color: var(--heading-color);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
  cursor: pointer;
}
ul.twitter-feed a span.fa{
    color: #00acee;
    margin-top:4px;
}
.sidebar-sticky-fix {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}
.sidebar-widget figure {
  margin: 0 0 0rem;
}

.sidebar-widget .search-form {
  display: flex;
  position: relative;
}

.sidebar-widget .search-form input {
  padding: 15px 60px 15px 20px;
  font-size: 17px;
  line-height: 26px;
  border: 1px solid var(--bg-border);
  background-color: var(--bg-white);
  outline: none;
  width: 100%;
  color: var(--para-color);
  border-radius: 6px;
}

.sidebar-widget .search-form button {
  text-align: center;
  background: var(--primary-color);
  border: none;
  outline: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  position: absolute;
  padding: 0px 0px;
  right: 16px;
  top: 13px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  line-height: 5px;
}
.blog-cat li.active a,
.blog-cat li a:hover {
  color: var(--primary-color);
}

/* Post Widget */
.popular-posts .post {
  position: relative;
  padding-left: 110px;
  margin-bottom: 25px;
}

.sidebar-widget:last-child {
  margin-bottom: 0px;
}

.popular-posts .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 85px;
  height: 85px;
  overflow: hidden;
}

.popular-posts .post .post-thumb img {
  display: block;
  width: 100%;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.popular-posts .post .text {
  position: relative;
  font-size: 20px;
  margin: 0px 0px 10px;
  font-weight: 600;
  color: var(--heading-color);
  line-height: 1.2em;
}

.popular-posts .post .text a {
  color: var(--heading-color);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.popular-posts .post a:hover,ul.twitter-feed a:hover {
  color: var(--primary-color);
}

.popular-posts .post-info {
  position: relative;
  font-size: 16px;
  color: var(--font-color);
  font-weight: 400;
}

/*Instagram Widget*/
.sidebar .instagram-widget .images-outer {
  position: relative;
  margin: 0px -4px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.sidebar .instagram-widget .image-box1 {
  position: relative;
  float: left;
  width: 33.3333%;
  padding: 0px 6px;
  margin-bottom: 12px;
}

.sidebar .instagram-widget .image-box:nth-child(3),
.sidebar .instagram-widget .image-box:nth-child(6) {
  margin-right: 0px;
}

.images-outer a img {
  border-radius: 8px;
}

.sidebar .instagram-widget .image-box1 .overlay-box {
  position: absolute;
  content: '';
  left: 14px;
  top: 10px;
  bottom: 10px;
  right: 14px;
  display: block;
  z-index: 1;
  opacity: 0;
  text-align: center;
  padding: 8px;
  color: #ffffff;
  font-size: 24px;
  line-height: 44px;
  transition: all 300ms ease;
  background-color: #49c0b6;
  border-radius: 8px;
}

.sidebar .instagram-widget .image-box .overlay-box:before {
  position: absolute;
  top: 50%;
  margin-left: -12px;
  margin-top: -22px;
  display: inline-block;
}

.sidebar .instagram-widget .image-box:hover .overlay-box {
  opacity: 1;
}

.sidebar .instagram-widget .image-box img {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 8px;
  transition: all 300ms ease;
}

/*Tags*/
.sidebar .popular-tags a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 6px 24px 6px;
  margin: 0px 4px 8px 0px;
  color: var(--para-color);
  text-align: center;
  font-size: 17px;
  border: 1px solid var(--bg-border);
  background-color: var(--bg-white);
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  -ms-border-radius: 6px;
  font-weight: 600;
}

.sidebar .popular-tags a:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #ffffff;
}

.subscribe-widget .widget-content {
  position: relative;
  padding: 32px 25px;
  background-color: var(--widget-bg);
  border-radius: 8px;
}

.subscribe-widget h5 {
  position: relative;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3em;
  padding-left: 30px;
  margin-bottom: 15px;
}

.subscribe-widget h5:before {
  position: absolute;
  content: "\f0e0";
  left: 0px;
  top: 2px;
  color: #ffffff;
  line-height: 1em;
  font-weight: 400;
  font-size: 20px;
  font-family: 'FontAwesome';
}
.sub-log{
  padding-Top: 600px;
  
}

.pt-5{
  margin-bottom: 5%;
}

